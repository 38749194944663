<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="g-3 form-deletion-rule-edit" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div class="row row-cols-1 mt-3 mb-3">
              <div class="col col-md-6">
                <label for="CustomObject" class="form-label required">{{
                  $t(
                    "DeletionRule.CustomObject",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  :isDetailIconShow="true"
                  :routeLink="String.format('#/CustomObject/Detail/')"
                  @onChange="onChangeCustomObject"
                  :selectedData="selectedCustomObject"
                  :allowEmpty="false"
                  :isParameters="true"
                  requestUrl="/Lcdp-SummaryCustomObjects?"
                />
              </div>
              <div class="col col-md-6">
                <label for="Viewfilter" class="form-label required">{{
                  $t(
                    "DeletionRule.ViewFilter",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithRemoteDataAndInternalSearch"
                  @onChange="onChangeSystemViewFilter"
                  :isDetailIconShow="true"
                  :routeLink="
                    String.format(
                      '#/CustomObject/EditViewFilter/{0}&viewFilterId=',
                      this.deletionRuleData.customObjectPublicId
                    )
                  "
                  :resetSelect="selectValueReset"
                  :selectedData="selectedDeletionRuleViewFilter"
                  :allowEmpty="false"
                  :isParameters="true"
                  :requestUrl="
                    String.format(
                      '/Lcdp-SummaryViewFilters?coId={0}',
                      this.deletionRuleData.customObjectPublicId
                    )
                  "
                  :isDisabled="
                    String.isNullOrWhiteSpace(
                      this.deletionRuleData.customObjectPublicId
                    )
                      ? true
                      : false
                  "
                />
              </div>
            </div>
            <div class="row row-cols-1">
              <div class="col col-md-12">
                <label for="Description" class="form-label"
                  >{{
                    $t(
                      "DeletionRule.Description",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <textarea
                  class="form-control"
                  :spellcheck="this.$isTextSpellCheck"
                  v-model="deletionRuleData.description"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <div class="col-12 mt-3">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="deletionRuleData.isActive"
                />
              </div>
            </div>
            <ActionButtons
              @methodSave="onSubmit('btn-save')"
              :isNewRecord="false"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "DeletionRuleNew",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      deletionRuleData: {},
      selectedDeletionRuleViewFilter: [],
      selectedCustomObject: [],
      selectValueReset: false,
      nullObject: [{ key: null, value: null }],
    };
  },
  methods: {
    onChangeCustomObject: function (selected) {
      this.deletionRuleData.customObjectPublicId = selected.key;
      this.selectValueReset = !this.selectValueReset;
      if (String.isNullOrWhiteSpace(selected.key)) {
        this.onChangeSystemViewFilter(this.nullObject);
      }
    },
    onChangeSystemViewFilter: function (selected) {
      this.deletionRuleData.viewFilterPublicId = selected.key;
      this.deletionRuleData.viewFilterName = selected.value;
    },
    onSubmit(buttonName) {
      var form = $(".form-deletion-rule-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-DeletionRuleEdit", { ...this.deletionRuleData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push("/CustomObjectDeletionRule/List");
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    getDeletionRule() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-DeletionRuleGet?id={0}",
            this.$route.params.deletionRuleId
          )
        )
        .then((response) => {
          this.deletionRuleData = response.data;

          if (
            !String.isNullOrWhiteSpace(this.deletionRuleData.viewFilterName) &&
            !String.isNullOrWhiteSpace(this.deletionRuleData.viewFilterPublicId)
          ) {
            this.selectedDeletionRuleViewFilter.push({
              key: this.deletionRuleData.viewFilterPublicId,
              value: this.deletionRuleData.viewFilterName,
            });
          }

          if (
            !String.isNullOrWhiteSpace(
              this.deletionRuleData.customObjectPublicId
            ) &&
            !String.isNullOrWhiteSpace(
              this.deletionRuleData.customObjectPublicId
            )
          ) {
            this.selectedCustomObject.push({
              key: this.deletionRuleData.customObjectPublicId,
              value: this.deletionRuleData.customObjectName,
            });
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getDeletionRule();
  },
};
</script>
